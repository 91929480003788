<template>
  <div id="app">
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/Footer";
export default {
  components:{Footer}
}


</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html,body,ul,ol,li,p,a{
  margin: 0;
  padding: 0;
  font-size: 12px;
}
h1,h2,h3,h4,h5,h6{
  margin: 0;
  padding: 0;
}
ul{
  list-style: none;
}
a{
  color: black;
  text-decoration: none;
  font-size: 12px;
}
a:hover{
  cursor: pointer;
}
input{
  outline: none;
}


</style>
