<template>
  <div class="con">
    <div class="con-mask">
      <div class="yday">
        <div class="yday-bg">
          <div class="yday-user">
            <div class="user-logo">
              <img src="../assets/images/mood-sgin/touxiang.jpg" alt="渔舟唱晚">
              <p>渔舟唱晚</p>
              <p>sign：那谁！你知道吗？</p>
            </div>
          </div>
          <div class="yday-text">
            <p v-text="content"></p>
            <p v-text="name"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MoodSign",
  data() {
    return {
      content: '渔舟唱晚，响穷彭蠡之滨；雁阵惊寒，声断衡阳之浦',
      name: '滕王阁序'
    }
  },
  mounted() {
    this.$axios({
      method: 'get',
      url: 'https://api.xygeng.cn/one'
    }).then(res => {
      if (res.data.code === 200) {
        this.content = res.data.data.content;
        this.name = res.data.data.name
      } else {
        console.log(res.data);
      }
    })
  }

}
</script>

<style scoped>
.con {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/mood-sgin/wallhaven-e7pwdw.jpg");
  background-repeat: no-repeat; /*不重复*/
  background-position: center; /*中心点*/
  background-size: cover; /*拉伸覆盖全部*/
}

.con-mask {
  /*background-color: #888888;*/
  width: 100%;
  height: 100vh;
  /*margin: 0 auto;*/
  display: flex;
  align-items: center;
}

.yday {
  /*background-image: url(../assets/images/mood-sgin/yday.jpg);*/
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1100px;
  height: 260px;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 auto;
}

.yday-bg {
  margin: 40px auto 0;
  /*width: 80%;*/
  width: 70%;
  height: 60%;
  /*background-color: #64626533;*/
  background-color: #e9d9d233;;
  display: flex;
  justify-content: space-between;
  box-shadow: 1px -1px 20px #888888;
  border-radius: 2px;
}

.yday-user {
  padding: 20px 0;
  width: 200px;
  text-align: center;
}

.yday-user img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  border: 2px solid white;
}

.yday-text {
  padding: 20px;
  width: 600px;
  height: 116px;
  position: relative;
}

.yday-text p:first-child {
  width: 80%;
  text-align: justify;
  color: #FFFFFF;
  font-size: 16px;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
}

.yday-text p:nth-child(2) {
  font-size: 16px;
  position: absolute;
  bottom: 20px;
  right: 30px;
  text-align: right;
  /*color: #c5c5d0;*/
  color: #2c3e50;
}
</style>