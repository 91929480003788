<template>

  <div class="footer">
    <div class="ipc">
      © 2022  <a href="https://www.uyimirin.top" target="_blank">uyimirin.top</a> 版权所有
      <a href="https://beian.miit.gov.cn/" target="_blank" v-text="ipcCode">浙ICP备2022017137号-1</a>
    </div>
    <div class="police">
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33092102000183">
        <img src="../assets/images/logo/policeBadge-icon.png" style="float:left;" alt="公网备案"/>
        <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px;" v-text="policeCode">浙公网安备 33092102000183号</p></a>
    </div>
    <div class="desc" >
       <p>[本网站存在原因：为个人开发测试技术学习而存在，无任何营利或其他性质。]</p>
    </div>
  </div>


</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      ipcCode: '浙ICP备2022017137号-1',
      policeCode: '浙公网安备 33092102000183号'
    }
  }
}
</script>

<style scoped>
.footer {
  padding:10px 0;
  background-color: #f3f3f3;
}
.ipc {
  height:20px;
  line-height:20px;
  width: 100%;
  padding: 2px 0;
}
.ipc,.ipc a {
  /*font-size: 16px;*/
  color:#939393;
}
.ipc a:hover {
  color: cornflowerblue;

}
.police {
  width:300px;
  margin:0 auto;

}
.police a {
  display:inline-block;
  text-decoration:none;
  height:20px;
  line-height:20px;
  color:#939393;
}
.police p:hover {
  color: cornflowerblue;
}
.desc {
  margin-top: 5px;
  color:#939393;
}
</style>